<template>
	<div class="searchbar">
		<el-row :gutter="20">
			<el-col class="search-item" v-for="(item, index) in queryOptions.conditionOption" :xs="24" :sm="12" :md="item.md || 4" :span="item.span || 6" v-bind:key="index">
				<span class="search-item-label" :style="{ width: item.labelwidth || '86px' }">{{ item.label }}</span>
				<el-select 
					size="small" 
					@change="selectVal($event,item.prop)" 
					v-if="item.type == 'dropDownList'" 
					class="valueOptions" 
					v-model="conditionData[item.prop]" 
					filterable clearable 
					:placeholder="item.tips || '请选择'"
				>
					<el-option v-for="(sitem, sindex) in item.option" :label="sitem.label" :value="sitem.value" :key="sindex"></el-option>
				</el-select>
				<el-cascader
					v-if="item.type == 'cascader'"
					v-model="conditionData[item.prop]"
					@change="cascaderVal($event,item.prop)"
					filterable
					clearable
					size="small"
					:placeholder="item.tips || '请选择'"
					:options="item.option"
					:props="{ expandTrigger: 'hover' }"
				></el-cascader>
				<el-date-picker
					class="valueOptions"
					v-if="item.type == 'date'"
					v-model="conditionData[item.prop]"
					align="right"
					type="date"
					size="small"
					value-format="yyyy-MM-dd"
					placeholder="选择日期"
					@change="getDateVal($event,item.prop)"
					:picker-options="pickerOptions"
				></el-date-picker>
				<el-date-picker
					class="valueOptions"
					v-if="item.type == 'daterange'"
					v-model="conditionData[item.prop]"
					type="daterange"
					align="right"
					unlink-panels
					size="small"
					value-format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="getDaterangeVal($event,item)"
					:picker-options="pickerOptionsBt"
				></el-date-picker>
				<el-input size="small" v-if="item.type == 'input'" @input="inputVal($event,item.prop)" class="valueOptions" v-model="conditionData[item.prop]" clearable :placeholder="item.tips || '请输入查询条件'"></el-input>
			</el-col>
		</el-row>
		<!-- <el-row>
			<el-col :span="24" class="search-condition-bar">
				<el-input class="valueOptions" v-model="conditionData.inputText" clearable placeholder="请输入内容"></el-input>
				<div><el-button slot="append" icon="el-icon-search" @click="searchList"></el-button></div>
			</el-col>
		</el-row> -->
	</div>
</template>

<script>
import { formatDate, formatStrToDate } from '../../assets/js/formatdate.js';
export default {
	props: {
		queryOptions: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			conditionData: {
				selectVal: '',
				cascaderVal: '',
				dateVal: '',
				daterangeVal: '',
				inputText: ''
			},
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
				shortcuts: [
					{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					},
					{
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					},
					{
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}
				]
			},
			pickerOptionsBt: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			}
		};
	},
	created() {
		this.$vue.$on("reset",_=>{
			for (let key in this.conditionData){
				this.conditionData[key] = ""
			}
		})
	},
	methods: {
		selectVal(e,prop){
			this.$emit("getSearchVal",{prop,e})
		},
		cascaderVal(e,prop){
			this.$emit("getSearchVal",{prop,e})
		},
		getDateVal(time,prop){
			const e = time+" 00:00:00"
			this.$emit("getSearchVal",{prop,e})
		},
		getDaterangeVal(e,item){
			const object = new Object();
			object[item.start] = e[0]
			object[item.end] = e[1]
			this.$emit("getSearchVal",{item,object})
		},
		inputVal(val,prop){
			let e = ""
			if(val){
				e+=val
			}
			e = e.trim()
			this.$emit("getSearchVal",{prop,e})
		},
		// searchList() {
		// 	console.log(this.conditionData);
		// 	this.$emit('search', this.conditionData);
		// }
	}
};
</script>

<style lang="scss" scoped>
.searchbar {
	max-width: 100%;
	margin: 18px 0 0 0;
	.search-item {
		display: flex;
		align-items: center;
		margin-bottom: 18px;
	}
	.el-button {
		border-radius: 0 4px 4px 0 !important;
		background-color: #F5F7FA;
	}
	.el-select {
		width: 100%;
	}
	.queryExpressions {
		.el-input__inner {
			border-radius: 4px 0 0 4px;
		}
	}
	.operator {
		.el-input__inner {
			border-radius: 0;
			border-right: 0;
			border-left: 0;
		}
	}
	.valueOptions {
		.el-input__inner {
			border-radius: 0;
			border-right: 0;
		}
	}
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 100%;
		border-radius: 0;
	}
	.search-condition-bar{
		display: flex;
		align-items: center;
		width: 400px;
	}
}
</style>
